import React from "react"
import { Helmet } from "react-helmet"

import Logo from "../components/logo.js"

// frontmatter or something later
const videos = [
  {
    "caption": "FasTrack - Beta - 360 Video Tutorial",
    "url": "https://www.youtube.com/embed/Katq0aPPs7o",
  },
  {
    "caption": "FasTrack - Beta - 2D Portrait Tutorial",
    "url": "https://www.youtube.com/embed/mrwVNZhGaDg",
  },
  {
    "caption": "FasTrack - Beta - 2D Landscape Tutorial",
    "url": "https://www.youtube.com/embed/8Q3-b_epzSA",
  },
]

export default function Home() {

  return (
    <main>
      <Helmet bodyAttributes={{
          class: 'blue-gradient'
        }}>
        <html lang="en" />
        <title>FasTrack Tutorials</title>
        <meta name="description" content="Tutorials for FasTrack" />
      </Helmet>
      <div className="relative container">
        <div className="w-full text-center pt-8">
          <Logo></Logo>
          <h1 className="mb-4 text-3xl font-bold uppercase text-white">Tutorials</h1>
          <p className="text-blue-200 mb-12">Last updated: Friday October 30th</p>
          {videos.map((video, index) => {
            return (
              <div className="mb-24" key={index}>
                <div className="relative bg-black shadow-xl" style={{'paddingTop': '56.25%'}}>
                  <iframe src={video.url} title={video.caption} className="absolute w-full h-full top-0 left-0"  frameBorder="0" allowFullScreen allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
                <p className="mt-4 text-lg text-white">{video.caption}</p>
              </div>
            )
          })}
        </div>
      </div>
    </main>
  )
}
